import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PdfContainer from "../components/PDF/PdfContainer";
import Loader from "../components/loader";
import { savePDF } from "@progress/kendo-react-pdf";
import img1 from "../assets/img/img1.jpg";
import logo from "../assets/img/logo.png";
import { render } from "@testing-library/react";
import moment from 'moment';
import {
  faCheck,
  faCog,
  faHome,
  faPlus,
  faSearch,
  faEllipsisH,
  faEye,
  faEdit,
  faTrashAlt,
  faDownload,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Table,
  Card,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  ProgressBar,
  Pagination,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { NewUserForm } from "../components/Forms";
import transactions from "../data/transactions";
import history from "../components/history";
import { Routes } from "../routes";
import { Link } from "react-router-dom";
import { getuser, deleteUser } from "../Commanservice/commanservice.dev";
import Doc from "../components/PDF/DocService";
import config from "../config/axios/config.json";
import Certificate from "../components/Certificate";
import { ToastContainer,toast } from "react-toastify";
// const Cryptr = require("cryptr");
// const cryptr = new Cryptr("myTotalySecretKey");
var QRCode = require("qrcode.react");
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];

export class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserList: [],
      filteredUserList: [],
      DownloadType: "Single",
      CurrentlyChecked: "",
      Loading: false,
      noofPages: 1,
      perPage: 250,
      activePage: 1,
      pageItems: [],
      isSearch: false,
      searchList: [],
      ForplantedyearFilter:[],
      defaultPage: [{
          pageNo: 'first',
          isActive: false
      }, {
          pageNo: 'prev',
          isActive: false
      }, {
          pageNo: 'next',
          isActive: false
      }, {
          pageNo: 'last',
          isActive: false
      }],
          isFilter: false,
          filterList:[]
    };
    this.fadeImage=this.fadeImage.bind(this);
  }

  componentDidMount() {
    // setTimeout(() => {
      this.setState({ Loading: false })
    getuser(`${APIURL.API_URL}/QRCode/read`)
      .then((response) => {
        if (response.isSuccess) {
          debugger;
          let pageitems = [];
          response.data.users
            .filter((data) => data.ID)
            .map((value) => (value.CheckedForPdf = false, value.Treeinfo = response.data.tree.filter((x, y) => x.ID == value.TreeName)[0]));
            let ForplantedyearFilter = []
         response.data.users.map((value,index) => {
           if(value.PlantedDuring && value.PlantedDuring != "" && ForplantedyearFilter.filter((a,b) => value.PlantedDuring == a.PlantedDuring).length == 0) {
             ForplantedyearFilter.push(value)
           }
         })
          this.setState({
            ForplantedyearFilter,
            UserList:response.data.users.filter(user => user.isDeleted === false),
            Loading: false,
            filteredUserList: response.data.users.filter(user => user.isDeleted === false),
          }, (() => {
            let filteredUserList = this.state.filteredUserList
            this.setState({ Loading: false,
               filteredUserList:this.setClientPagination(filteredUserList, 1),
              noofPages: Math.ceil(response.data.users.length / this.state.perPage) 
            })
          }));
          for (let i = 1; i <= Math.ceil(response.data.users.length / this.state.perPage); i++) {
            pageitems.push({
                pageNo: i,
                isActive: i == 1 ? true : false
            })
        }
        this.setState({ pageItems: pageitems,Loading: false })
        this.setPageItem(1, false, '',false)
        }
      })
      .catch((e) => {
        this.setState({ Loading: false })
        console.log("Error", e);
      });
    // }, 100);
  }
  fadeImage(){
      const htmlButtons = document.getElementsByClassName("Checking");
      const htmlButtonsArray = [...htmlButtons];
      htmlButtonsArray.map((button) => (button.checked = false));
      let filteredUserList = this.state.filteredUserList
      filteredUserList
      .map((a, b) => (a.CheckedForPdf = false));
      this.setState({filteredUserList})
  }
  setClientPagination = (UserList, activePage) => {
    let { perPage } = this.state
    let pageNo = activePage ;
    let filteredUserList = UserList.slice(((pageNo - 1) * perPage), (((pageNo - 1) * perPage) + perPage))
    return filteredUserList;
}
setPageItem = (pageNo, isSearch, Data,isFilter) => {
  const htmlButtons = document.getElementsByClassName("Checking");
  const htmlButtonsArray = [...htmlButtons];
  if (document.getElementsByClassName("MainId")) {
    document.getElementsByClassName("MainId")[0].checked = false;
  }
  htmlButtonsArray.map((button,index) =>  button.checked = false);
    let pageitems = [];
    let { pageItems, defaultPage } = this.state
    let pages = defaultPage;
    let tempItem = pageItems;
    if (isSearch) {
      for (let i = 1; i <= Math.ceil(Data.length / this.state.perPage); i++) {
        pageitems.push({
            pageNo: i,
            isActive: i == 1 ? true : false
        })
    }
    }
    else if (isFilter) {
      for (let i = 1; i <= Math.ceil(Data.length / this.state.perPage); i++) {
        pageitems.push({
            pageNo: i,
            isActive: i == pageNo ? true : false
        })
    }  
    }
    else {
      tempItem.map(x => {
        if (x.pageNo == pageNo) {
            x.isActive = true
        }
        else {
            x.isActive = false
        }
    })   
    }
    pages.map(x => x.isActive = false)
    this.setState({
        activePage: parseInt(pageNo),
        filteredUserList: isSearch||isFilter ? this.setClientPagination(Data, pageNo) : this.setClientPagination(this.state.UserList, pageNo),
        pageItems: isSearch ||isFilter ? pageitems : tempItem,
        noofPages: isSearch ? Math.ceil(this.state.UserList.length / this.state.perPage ):isFilter ?Math.ceil(this.state.filterList.length / this.state.perPage): this.state.noofPages,
        defaultPage: pages
    })
}
  SearchUser = (e) => {
    const searchUser = e.target.value.toLowerCase();
    if (searchUser == "")
      this.setState({ filteredUserList: this.state.UserList,isSearch: false,searchList: [] });
    else {
      let filteredUserList = this.state.UserList;
      filteredUserList = filteredUserList.filter((x) =>
        x.CustomerName.toLowerCase().includes(searchUser) ||
        x.CertNo.toLowerCase().includes(searchUser)
      );
      this.setState({ filteredUserList: filteredUserList,isSearch: true,searchList: filteredUserList});
      this.setPageItem(1, true, filteredUserList,false)
      console.log("filtered", filteredUserList);
    }
  };
  getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }
  FindData = () => {
    console.log("present");
  };
  DownloadCertificate = (html, user, index) => {
    debugger;
    sessionStorage.setItem("pressed", "press");
    let filteredUserList = this.state.filteredUserList;
    filteredUserList[index].CheckedForPdf = true;
    this.setState({ CurrentlyChecked: index, filteredUserList });
    setTimeout(() => {
      this.setState({ CurrentlyChecked: "" });
    }, 200);
    // Doc.createPdf(html,user)
  };

  selectAll = () => {
    const htmlButtons = document.getElementsByClassName("Checking");
    const htmlButtonsArray = [...htmlButtons];
    if (document.getElementsByClassName("MainId")[0].checked) {
      htmlButtonsArray.map((button,index) => {if(index<251) { button.checked = true}});
      let filteredUserList = this.state.filteredUserList;
      filteredUserList.map((a, b) => (a.CheckedForPdf = true));
      this.setState({ filteredUserList });
    } else {
      htmlButtonsArray.map((button,index) => {if(index<251) { button.checked = false}});
      let filteredUserList = this.state.filteredUserList;
      filteredUserList.map((a, b) => (a.CheckedForPdf = false));
      this.setState({ filteredUserList });
    }
  };
  deleteData = (item) => {
    let userdata = JSON.parse(sessionStorage.getItem("User"))
    if (window.confirm("Are you sure you want to delete this data?")) {
      console.log(item);
      deleteUser(`${APIURL.API_URL}/QRCode/deleteUser`, {item :item, deletedby:userdata[0].email})
      .then((response) => {
        if (response.isSuccess) {
          this.componentDidMount();
         toast.success("Removed Successfully")
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    }
  };  
  checkbox = (e, index) => {
    let filteredUserList = this.state.filteredUserList;
    if (document.getElementsByClassName("Checking")[index].checked) {
      filteredUserList
        .filter((p, q) => q == index)
        .map((a, b) => (a.CheckedForPdf = true));
    } else {
      filteredUserList
        .filter((p, q) => q == index)
        .map((a, b) => (a.CheckedForPdf = false));
    }
    this.setState({ filteredUserList, CurrentlyChecked: index });
  };
  createPdf = (html, user) => {
    Doc.createPdf(html, user);
  };
  FilterByPlantedYear = (e) => {
    let filteredUserList = this.state.filteredUserList;
    if (e.target.value == "-1") {
      this.setState({ isFilter:false,filterList:filteredUserList,filteredUserList: this.setClientPagination(this.state.UserList, 1) });
    } else {
      filteredUserList = this.state.UserList.filter(
        (a, b) => a.PlantedDuring == e.target.value
      );
      this.setState({ isFilter:true,filterList:filteredUserList})
      this.setPageItem(1, false,filteredUserList,true)
      // this.setState({ filteredUserList:this.setClientPagination(filteredUserList, 1) });
    }
  };
  resetPDF=(item)=>{
    console.log(item)
    if(item!=''){
    this.state.filteredUserList.map((a, b) =>{ 
      if(a.CertNo == item.CertNo){
        a.CheckedForPdf=false
      }
    });
  }
  }
  defaultPagination = (pageType, activePage) => {
    let len=this.state.isFilter?Math.ceil(this.state.filterList.length/this.state.perPage):this.state.isSearch?Math.ceil(this.state.searchList.length/this.state.perPage):Math.ceil(this.state.UserList.length/this.state.perPage)
    if(activePage==0||activePage>len){
      if (pageType == 'first') {
        activePage=1
      }
      else if (pageType == 'prev') {
        activePage=this.state.activePage-1
      }
      else if (pageType == 'next') {
        activePage=len==this.state.activePage?len:this.state.activePage+1
      }
      else{
        activePage=len
      }
    }
    let { pageItems,defaultPage } = this.state
    let tempItem = defaultPage,pageitems=pageItems;
    this.setState({ activePage: activePage, filteredUserList:this.state.isFilter?this.setClientPagination(this.state.filterList, activePage) : this.setClientPagination(this.state.UserList, activePage) })
    pageitems.map(x=>x.isActive=false)
    tempItem.map((x, ind) => {
        if (pageType == 'first') {
            if (ind == 0) {
                tempItem[0].isActive = true
            }
            else {
                tempItem[ind].isActive = false
            }
        }
        else if (pageType == 'prev') {
            if (ind == 1) {
                tempItem[1].isActive = true
            }
            else {
                tempItem[ind].isActive = false
            }
        }
        else if (pageType == 'next') {
            if (ind == 2) {
                tempItem[2].isActive = true
            }
            else {
                tempItem[ind].isActive = false
            }
        }
        else if (pageType == 'last') {
            if (ind == 3) {
                tempItem[3].isActive = true
            }
            else {
                tempItem[ind].isActive = false
            }
        }
    })

    this.setState({ defaultPage: tempItem,pageItems:pageitems })
}
  render() {
    const totalTransactions = this.state.filteredUserList.length;
    let { perPage, activePage } = this.state
    return (
      <>
        <Loader loader={this.state.Loading}></Loader>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item
                onClick={() => (window.location.href = "/dashboard/overview")}
              >
                 <FontAwesomeIcon icon={faHome} />  Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Client Details</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Client Details</h4>
            {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
              <Button
                variant="outline-primary"
                size="sm"
                as={Link}
                to={Routes.Upgrade.path}
              >
                Add
              </Button>
              <Button
                type="file"
                variant="outline-primary"
                size="sm"
                as={Link}
                to={Routes.BootstrapTables.path}
              >
                Excel upload
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div
          style={{ display: "flex" }}
          className="table-settings mb-4 Displaysettings"
        >
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              onChange={(e) => this.SearchUser(e)}
              placeholder="Search by Customer Name or Certificate Number"
            />
          </InputGroup>
          <select
            id="DropDown"
            className="Dropdown"
            onChange={(e) => {
              this.FilterByPlantedYear(e);
            }}
            name="GameList"
          >
            <option id="-1" value="-1">
              All Planted Years
            </option>
            {this.state.ForplantedyearFilter.map((t, index) => {
              return (
                <option id={t.index} value={t.index} className="Options">
                  {t.PlantedDuring}
                </option>
              );
            })}
          </select>
          <div>
            <PdfContainer
              CurrentID={this.state.CurrentlyChecked}
              userData={this.state.filteredUserList.filter(
                (a, b) => a.CheckedForPdf==true
              )
            }
              fadeImage={this.fadeImage}
              resetPDF={this.resetPDF}
            ></PdfContainer>
          </div>
        </div>

        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">
                    <input
                      className="MainId"
                      onClick={(e) => this.selectAll(e)}
                      type="checkbox"
                    />
                  </th>
                  <th className="border-bottom">S.No </th>
                  <th className="border-bottom">Customer Name</th>
                  {/* <th className="border-bottom">E-mail</th> */}
                  <th className="border-bottom">Origination Group Name</th>
                  <th className="border-bottom">Certificate Number</th>
                  <th className="border-bottom">Organization</th>
                  <th className="border-bottom">Tree Name</th>
                  <th className="border-bottom">Planted During</th>
                  <th className="border-bottom">Updated Date</th>
                  <th className="border-bottom">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredUserList.map((t, index) => (
                  <tr key={`transaction-${t.invoiceNumber}`}>
                    <td>
                      <input
                        className="Checking"
                        onClick={(e) => this.checkbox(e, index)}
                        type="checkbox"
                        id={index}
                      />
                    </td>
                    <td>{(this.state.activePage - 1) * this.state.perPage +index + 1}</td>
                    <td>{t.CustomerName}</td>
                    <td>{t.OriginationGroupName}</td>
                    <td>{t.CertNo}</td>
                    <td>{t.CurrentOrganization}</td>
                    <td>{t.Treeinfo?.TreeName}</td>
                    <td>{t.PlantedDuring}</td>
                    <td>{moment(t.updatedDate).format("DD-MM-YYYY, hh:mm:ss a")}</td>
                    <td>
                      <div style={{ display: "flex" }}>
                        <button
                          className="actionbutton"
                          onClick={(e) =>
                            this.DownloadCertificate(
                              document.getElementsByClassName("pdf-body")[0],
                              this.state.filteredUserList.filter(
                                (a, b) => b == index
                              ),
                              index
                            )
                          }
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="me-2"
                          />{" "}
                        </button>
                        <button
                          className="actionbutton"
                          onClick={(e) =>
                            window.open(`/UserPage/?id=${btoa(t.ID)}`)
                          }
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                        </button>
                        <Dropdown>
                          <Dropdown.Toggle
                            as={Button}
                            split
                            variant="link"
                            className="text-dark m-0 p-0"
                          >
                            <span className="icon icon-sm">
                              <FontAwesomeIcon
                                icon={faEllipsisH}
                                className="icon-dark"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu   className="dropdown-menu pull-left">
                            {/* <Dropdown.Item>
                              <FontAwesomeIcon
                                icon={faHistory}
                                className="me-2"
                              />History
                            </Dropdown.Item> */}
                            <Dropdown.Item onClick={() => {
                                console.log("edit")
                                  sessionStorage.setItem(
                                    "editData",
                                    JSON.stringify(t)
                                  );
                                  sessionStorage.setItem("isEdit", true);
                                  window.location.href="/AddNewUsers"
                                }}>
                              <FontAwesomeIcon icon={faEdit}   className="me-2" />
                                Edit
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                  this.deleteData(t);
                                }} className="text-danger">
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="me-2"
                              />{" "}
                                Remove
                              
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.state.filteredUserList.length > 0 ?
            // {this.state.pageItems.length>1?
                            <Pagination>
                                <Pagination.First onClick={(e) => this.defaultPagination('first', 1)} active={this.state.defaultPage[0].isActive} />
                                <Pagination.Prev onClick={(e) => this.defaultPagination('prev', this.state.activePage - 1==0?1:this.state.activePage - 1)} active={this.state.defaultPage[1].isActive} />
                                {this.state.pageItems.map((p, index) => (
                                    <Pagination.Item key={index} onClick={(e) => this.setPageItem(e.currentTarget.text ? e.currentTarget.text : this.state.activePage, this.state.isSearch ? true : false, this.state.isSearch ? this.state.searchList :  this.state.isFilter ?this.state.filterList :'',this.state.isFilter ? true : false)} active={p.isActive}>
                                        {p.pageNo}
                                    </Pagination.Item>
                                ))}
                              <Pagination.Next onClick={(e) => this.defaultPagination('next',  this.state.activePage + 1)} active={this.state.defaultPage[2].isActive} />
                              <Pagination.Last onClick={(e) => this.defaultPagination('last', this.state.noofPages)} active={this.state.defaultPage[3].isActive} />
                            </Pagination> : null}
          </Card.Body>
          <ToastContainer
              autoClose={3000}
              hideProgressBar={true}
              limit={0}
              draggable={false}
              position={toast.POSITION.TOP_RIGHT}
            ></ToastContainer>
        </Card>
      </>
    );
  }
}

export default Transaction;
